<template>
    <div class="live-pause-countdown" v-if="isShow">
        <div class="container">
            <hint-dialog>
                <div class="title-hint">您已暂停直播，观众无法看到画面</div>
                <div class="time">{{ countdown | formatTime }}</div>
                <div class="hint">后将自动关闭直播</div>
                <el-button class="continue" @click="liveContinueHandler">继续直播</el-button>
            </hint-dialog>
        </div>
    </div>
</template>

<script>
import HintDialog from "./containers/HintDialog";

export default {
    name: "LivePauseCountdown",
    data() {
        return {
            countdown: 300,
            timer: void 0,
        };
    },
    props: {
        isShow: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        isShow(newVal) {
            if (newVal) {
                this.countdown = 300;
                this.useCountdown();
            }
        },
    },
    computed: {},
    components: {
        HintDialog,
    },
    methods: {
        liveContinueHandler() {
            this.$emit("live-continue");
        },
        useCountdown() {
            this.timer && clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.countdown--;
                this.countdown > 0 ? this.useCountdown() : this.liveContinueHandler();
            }, 1000);
        },
    },
    filters: {
        formatTime(time) {
            const value = time * 1;
            const getValue = (value) => ((value + "").length <= 1 ? `0${value}` : value);
            let minute = getValue(Math.floor(value / 60) % 60);
            let second = getValue(value % 60);
            return [minute, second].join(":");
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.container {
    .title-hint {
        font-size: 18px;
    }
    .time {
        margin: 70px 0 19px;
        font-size: 34px;
        font-weight: 700;
    }
    .hint {
        margin-bottom: 60px;
        font-size: 16px;
    }
    .continue {
        width: 140px;
        height: 40px;
        color: #fff;
        background-color: #4b45ff;
        border-radius: 20px;
    }
}
</style>
