<template>
    <div class="live-room" element-loading-text="连接中" v-if="roomId">
        <div class="container">
            <!-- <div class="data-board" v-if="roomId && liveState == 1" @click="toDataBoard"> -->
            <!-- <div class="data-board">
                <div class="line">查看</div>
                <div class="line">{{ 0 ? "当前" : "所有" }}</div>
                <div class="line">脚本</div>
            </div> -->
            <live-script
                ref="liveScript"
                class="radius"
                :scriptData="scriptCarousel"
                :liveState="liveState"
                :currentScript="currentScript"
            ></live-script>
            <live-main-stream
                class="radius"
                ref="liveMain"
                :room-id="roomId"
                @start-live.once="sendStarLive"
                @setProp="setProp"
                @changeData="setChangeData"
                @bulletChatChange="setBulletChat"
            ></live-main-stream>
            <live-other-info
                :popularityData="popularityData"
                :bulletChatList="bulletChatList"
                :liveState="liveState"
                :liveTime="liveTime"
            ></live-other-info>
        </div>
        <my-dialog
            title="提示"
            :is-show.sync="debugDialog"
            :button-config="debugDialogButton"
            :confirm-handler="startLiveInit"
            :cancel-handler="toDebugger"
            :top="50"
        >
            为了避免直播中的失误，您可以选择先进行直播调试后再开始直播， 或者您也可以选择直接开始。
        </my-dialog>
        <el-dialog :visible.sync="qrcodeStatus" title="扫码开启直播" width="30%" :show-close="false" :close-on-click-modal="false">
            <div class="qrcode-body">
                <img :src="qrcodeImg" v-if="qrcodeImg" />
            </div>
            <div slot="footer">
                <el-button type="primary" @click="confirmPushStatus">已开启手机端直播</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import LiveScript from "components/student/liveRoom/LiveScript";
import LiveMain from "components/student/liveRoom/LiveMain";
import LiveMainStream from "@/components/student/liveRoom/LiveMainStream.vue";
import LiveOtherInfo from "components/student/liveRoom/LiveOtherInfo";
import MyDialog from "components/common/MyDialog";
import Qrcode from "qrcode"
import {mapGetters, mapActions} from "vuex";

import {getIp, getSteamLocation, liveRoom_list} from "@/utils/apis.js";
import {rtmpUrl, hlsBaseUrl} from "@/utils/common";
import Hls from "hls.js";

export default {
    name: "LiveRoom",
    data() {
        return {
            qrcodeStatus: true,
            liveTime: null,
            currentScript: {},
            liveState: 1,
            roomId: this.$route.query.id || "",
            connectLoading: false,
            //左侧脚本控制相关
            scriptCarousel: [],
            //右侧直播间信息相关
            popularityData: {
                onlineTotalNum: 0,
                inOnlineNum: 0,
                outOnlineNum: 0,
                dealNum: 0,
                like: 0,
            },
            bulletChatList: [],
            //dialog
            debugDialogButton: [{ hidden: true }, { text: "直接开播" }],
            debugDialog: false,
            room_id: this.$route.query.id,
            qrcodeImg: ""
        };
    },
    provide: function () {
        const { toggleDebugDialog } = this;
        return {
            toggleDebugDialog,
        };
    },
    computed: {
        ...mapGetters(["streamIpValue"])
    },
    components: {
        LiveScript,
        LiveMain,
        LiveOtherInfo,
        MyDialog,
        LiveMainStream
    },
    methods: {
        ...mapActions(["SET_STREAM_IP"]),
        async confirmPushStatus() {
            if (Hls.isSupported()) {
                let hls = new Hls({
                    liveSyncDurationCount: 2,
                    liveMaxLatencyDurationCount: 3,
                })
                const url = await hlsBaseUrl()
                console.log("url", url);
                hls.loadSource(url)
                hls.on(Hls.Events.ERROR, (event, data) => {
                    console.log("data", data)
                    if (data.fatal) {
                        switch (data.type) {
                            case Hls.ErrorTypes.NETWORK_ERROR:
                                console.log("网络异常的问题")
                                this.$message.warning("还没开始直播，请先在手机端开启直播")
                                break;
                            case Hls.ErrorTypes.MEDIA_ERROR:
                                console.log("Media Error:", data)
                                this.$message.warning("设备异常")
                                hls.recoverMediaError()
                            default:
                                hls.destroy()
                                break;
                        }
                    }
                })
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.qrcodeStatus = false;
                    hls.destroy();
                })
            }
            // this.qrcodeStatus = false
        },
        async init() {
            this.useRoomId();
        },
        async useRoomId() {
            // const res = await liveRoom_list();
            // if (res.code == 200 && res.data.length > 0) {
            //     this.roomId = res.data[0].id;
            // }
        },
        setProp({ key, value }) {
            this[key] = value;
        },
        setViewInfo(res) {},
        toggleDebugDialog(state) {
            this.debugDialog = state;
        },
        startLiveInit() {
            if (!this.roomId) {
                this.$message.error("请确保已创建直播间，如已创建请重新刷新网页重试");
                return void 0;
            }
            this.debugDialog = false;
            this.$refs.liveMain.startButtonHandler();
        },

        setChangeData(res) {
            const { like } = res.data;
            const { leave_online_number, online_total_number, in_online_number, deal_number } = res.data;
            const getValue = (value) => (value ? value : 0);
            this.popularityData.outOnlineNum = getValue(leave_online_number);
            this.popularityData.onlineTotalNum = getValue(online_total_number);
            this.popularityData.inOnlineNum = getValue(in_online_number);
            this.popularityData.dealNum = getValue(deal_number);
            this.popularityData.like = getValue(like);
            // this.$set(this.userInfo, "fans", getValue(fans));
            // this.$set(this.userInfo, "like", getValue(like));
            // this.ws.send(JSON.stringify(this.wsActions.getScriptInfo));
        },
        setBulletChat(arr) {
            this.bulletChatList = arr;
        },
        toDataBoard() {
            let url = this.$router.resolve({
                name: "studentLiveDatas",
                path: "/livedata/livedatas",
                query: {
                    user_id: localStorage.getItem("studentId"),
                    train_id: localStorage.getItem("train_id"),
                    room_id: this.roomId,
                },
            });
            window.open(url.href, "_blank");
        },
        toDebugger() {
            this.$router.push("/liveDebug");
        },
        async generateQcode() {
            const ip = await getIp()
            const url = rtmpUrl(ip)
            await this.SET_STREAM_IP(ip)
            Qrcode.toDataURL(url, (err, qrcodeUrl) => {
                if (err) throw err
                this.qrcodeImg = qrcodeUrl
            })
        }
    },
    mounted() {
        this.scriptId = localStorage.getItem("scriptId");
        this.init();
        this.generateQcode()
    },
    beforeDestroy() {
        const liveMain = this.$refs.liveMain;
        if(!liveMain) return;
        liveMain.stopLive(true);
    },
};
</script>

<style scoped lang="scss">
.qrcode-body {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.live-room {
    position: relative;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    user-select: none;

    .container {
        position: relative;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        padding: 20px;
        background-color: #070932;
        .data-board {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 22px;
            width: 104px;
            height: 93px;
            color: #fff;
            font-size: 14.5px;
            font-weight: 700;
            background: url("../../../assets/images/live/flag.png") no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
        }
    }
}

.radius {
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    /* background-color: #0c0e3f; */
}
::v-deep .popularity,
::v-deep .bullet-chat {
    @extend .radius;
}
</style>
