<template>
    <div class="not-start-script">
        <div class="script-containers">
            <div class="line"></div>
            <div class="total-list">
                <template v-for="(item, index) in packScriptData">
                    <div class="total-item" :key="item.id">
                        <div class="content">
                            <div class="script-snippet-box" :class="getCurrentClass(item)">
                                <div class="script-snippet-item">
                                    <div class="time" v-if="item.endTime">{{ item.startTime | getViewTime(index) }}</div>
                                    <div class="time icon" :class="[item.type == 1 ? 'start' : 'goods']" v-else>
                                        <div class="iconfont" v-html="item.icon"></div>
                                    </div>
                                    <div class="text">
                                        <div v-if="item.goods_id" style="font-size: 16px;">{{ item.type_content }}</div>
                                        <div>{{ item.script_content }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotStartScript",
    data() {
        return {};
    },
    props: {
        scriptData: {
            type: Array,
            default: () => [],
        },
        liveState: {
            type: Number,
            default: 0,
        },
        currentScript: {
            type: Object,
            default: () => {},
        },
        scriptType: {
            type: Number,
            default: 0
        }
    },
    computed: {
        packScriptData() {
            let nodeCount = 1;
            const list = [];
            const marks = [1, 1, 1];
            const titles = ["脚本", "福袋", "商品"];
            const getScriptNode = (type, icon, script_content) => ({
                type,
                icon,
                script_content
            })
            this.scriptData.forEach((item, tindex, arr) => {
                if(tindex == 0) {
                    if(item.goods_id) {
                        list.push(getScriptNode(2, nodeCount++, item.good_info.goods_name));
                    }else {
                        const valid = item.type_content == "开场预热";
                        list.push(getScriptNode(valid ? 1 : 2, valid ? '\ue62d' : nodeCount++, item.type_content))
                    }
                }else if(item.goods_id && item.goods_id != arr[tindex - 1].goods_id) {
                    list.push(getScriptNode(2, nodeCount++, item.good_info.goods_name));
                }else if(!item.goods_id && item.type_content != arr[tindex - 1].type_content) {
                    list.push(getScriptNode(2, nodeCount++, item.type_content));
                }
                const index = item.con_type - 1;
                const obj = {
                    ...item,
                    type: item.con_type,
                    title: `${titles[index]}${marks[index]}`,
                };
                list.push(obj);
                marks[index] = marks[index] + 1;
            });
            return list;
        },
    },
    components: {},
    filters: {
        getViewTime(sumSecond, index) {
            if (index == 1) return "0″";
            const minute = parseInt(sumSecond / 60);
            const second = sumSecond % 60;
            return minute > 0 ? `${minute}′${second}″` : `${second}″`;
        }
        // getBagAndGoodsTime(children, item, opeIndex) {
        //     const second = children.reduce((sum, numItem, numIndex) => sum + (numIndex <= opeIndex ? numItem.answer_time * 1 : 0), 0);
        //     return item.end_time * 1 + second;
        // },
    },
    methods: {
        getCurrentClass(item) {
            if(this.liveState == 1 && this.scriptType == 0 && this.currentScript && this.currentScript.startTime == item.startTime) {
                return { 'color-white': true }
            }
            return;
        }
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.color-white {
    color: #fff !important;
}
.not-start-script {
    position: relative;
    width: 100%;
    height: 100%;
    .script-containers {
        position: relative;
        width: 100%;
        height: fit-content;
    }
    .line {
        position: absolute;
        top: 0;
        left: 15%;
        bottom: 20px;
        width: 1px;
        background-color: #444797;
    }
    .total-list {
        .total-item {
            display: flex;
            flex-direction: column;
            padding-bottom: 20px;
            .title-box {
                position: relative;
                display: flex;
                align-items: center;
                align-self: flex-end;
                width: 85%;
                margin-bottom: 15px;
                font-size: 18px;
                transform: translateX(-13px);
                .sequential-num {
                    width: 26px;
                    height: 26px;
                    margin-right: 10px;
                    line-height: 26px;
                    text-align: center;
                    border-radius: 50%;
                    background-color: #bfbfbf;
                }
            }
            .content {
                display: flex;
                width: 100%;
                .script-snippet-box {
                    width: 100%;
                    box-sizing: border-box;
                    /* opacity: 0.6; */
                    line-height: 30px;
                    color: #8e8e97;
                    overflow: hidden;
                    transition: color .3s;
                    .script-snippet-item {
                        display: flex;
                        width: 100%;
                        overflow: hidden;
                        
                        
                        .time {
                            box-sizing: border-box;
                            width: 15%;
                            padding-right: 15px;
                            text-align: end;
                            overflow: hidden;
                            &.icon {
                                display: flex;
                                justify-content: flex-end;
                                padding-right: 0;
                                color: #fff;
                                overflow: unset;
                                + .text {
                                    color: #fff;
                                    font-size: 18px;
                                }
                                .iconfont {
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    width: 30px;
                                    height: 30px;
                                    border-radius: 37%;
                                    background-color: #f49d19;
                                    transform: translateX(50%);
                                    z-index: 9;
                                }
                                &.goods .iconfont {
                                    border-radius: 50%;
                                    background-color: #bfbfbf;
                                }
                            }
                        }
                        .text {
                            display: flex;
                            flex-direction: column;
                            box-sizing: border-box;
                            width: 85%;
                            padding: 0 30px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
}
</style>
