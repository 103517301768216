<template>
    <div class="live-script">
        <div class="data-board" v-if="liveState == 1" @click="toggleScriptType(!scriptType)">
            <div class="line">查看</div>
            <div class="line">{{ scriptType ? "所有" : "当前" }}</div>
            <div class="line">脚本</div>
        </div>
        <div class="container">
            <div class="head-box">
                <img class="decorate" src="~assets/images/live/scriptLeftDecorate.png" />
                <div class="title">直播带货脚本</div>
                <img class="decorate" src="~assets/images/live/scriptRightDecorate.png" />
            </div>
            <div class="content">
                <el-scrollbar style="height: 100%; width: 100%;">
                    <div class="script-content">
                        <template v-if="scriptData.length > 0">
                            <!-- <el-scrollbar style="height: 100%; flex: 1" v-if="liveState == 0"> -->
                                <not-start-script v-if="liveState == 0 || (liveState == 1 && scriptType == 0)" v-bind="$props" :scriptType="scriptType"></not-start-script>
                            <!-- </el-scrollbar> -->
                            <div v-else class="live-ing-script">
                                <span class="text">{{ currentScript.script_content }}</span>
                            </div> 
                        </template>
                        <template v-else>
                            <div class="empty">无脚本信息</div>
                        </template>
                    </div>
                </el-scrollbar>
            </div>
        </div>
    </div>
</template>

<script>
import NotStartScript from "./liveScript/NotStartScript";
export default {
    name: "LiveScript",
    data() {
        return {
            scriptType: 1 //0所有脚本，1当前脚本
        };
    },
    props: {
        scriptData: {
            type: Array,
            default: () => [],
        },
        liveState: {
            type: Number,
            default: 0,
        },
        currentScript: {
            type: Object,
            default: () => {},
        },
    },
    computed: {},
    components: {
        NotStartScript,
    },
    methods: {
        toggleScriptType(newV) {
            this.scriptType = Number(newV);
        }
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.live-script {
    box-sizing: border-box;
    //flex: 6;
    width: 35%;
    font-size: 14px;
    color: #fff;
    background-color: #0c0e3f;
    .container {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        .head-box {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100px;
            width: 100%;
            font-size: 24px;
            .title {
                margin: 0 24px;
                margin-top: -2px;
            }
            .decorate {
                width: 41px;
                height: 15px;
                vertical-align: middle;
            }
        }
        .content {
            position: relative;
            flex: 1;
            display: flex;
            justify-content: center;
            overflow: hidden;
            .script-content {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .live-ing-script {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                width: 85%;
                height: 100%;
                font-size: 24px;
                line-height: 72px;
                letter-spacing: 1px;
                word-break: break-all;
                overflow: hidden;
                .text {
                    /* transform: translateY(-30px); */
                }
            }
            .empty {
                position: absolute;
                top: 40%;
                font-size: 20px;
                color: #aaa;
            }
        }
    }
}
.data-board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 22px;
    width: 104px;
    height: 93px;
    color: #fff;
    font-size: 14.5px;
    font-weight: 700;
    background: url("../../../assets/images/live/flag.png") no-repeat;
    background-size: 100% 100%;
    cursor: pointer;
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .el-scrollbar__view {
    display: flex;
    min-width: 100%;
    min-height: 100%;
}
</style>
